import * as React from "react";
import { Link } from "gatsby";
import { Grid, ListItem, Paper, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import { DeleteCartItemButton } from "./DeleteCartItemButton";
import { CardImage } from "../cards/CardImage";
import { useCard } from "../cards/hooks";
import { splitSku } from "../products/utils";
import { CardQuantitySelector } from "../cards/CardQuantitySelector";
import { useUpdateLineItem } from "./hooks";
import { LocalPrice } from "../../common/LocalPrice";

export const LineItem = ({ product }) => {
    const cardId = splitSku(product.attributes.sku_code).cardId;
    const { isLoading, data: card } = useCard(cardId);
    const [maxQuantity, setMaxQuantity] = React.useState();
    const [quantity, setQuantity] = React.useState(product.attributes.quantity);
    const quantityMutation = useUpdateLineItem();
    const useStyles = makeStyles((theme) => ({
        paper: {
            padding: theme.spacing(1),
            margin: `auto`,
        },
        centerItem: {
            margin: `auto 0`,
        },
    }));
    const classes = useStyles();
    const handleQuantityChange = (newQuantity) => {
        if (newQuantity !== quantity) {
            setQuantity(newQuantity);
            quantityMutation.mutate(
                { id: product.id, quantity: newQuantity },
                {
                    onError: (error) => {
                        const errors = JSON.parse(error.message).errors;
                        if (errors[0].code === `VALIDATION_ERROR`) {
                            setQuantity(errors[0].meta.count);
                            setMaxQuantity(errors[0].meta.count);
                        }
                    },
                }
            );
        }
    };

    return product && !isLoading && card ? (
        <ListItem>
            <Paper className={classes.paper}>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Link to={`/card/${card.data.cardId}`}>
                            <CardImage
                                alt={card.data.name}
                                assetUrl={card.data.image.data.asset_url}
                                fullUrl={card.data.imageUrl}
                                height={200}
                                width={143}
                            />
                        </Link>
                    </Grid>
                    <Grid container item spacing={2} xs={6}>
                        <Grid item xs={12}>
                            <Typography
                                className={`truncate`}
                                gutterBottom
                                variant="subtitle1"
                            >
                                <Link to={`/card/${card.data.cardId}`}>
                                    {card.data.name}
                                </Link>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography gutterBottom variant="body2">
                                {product.details}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <CardQuantitySelector
                                handleQuantityChange={handleQuantityChange}
                                label=""
                                margin="none"
                                maxQuantity={maxQuantity}
                                quantity={quantity}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item xs={2}>
                        <Grid item>
                            <Typography variant="subtitle1">
                                <LocalPrice
                                    fromCurrencyCode={
                                        product.attributes.currency_code
                                    }
                                    fromPrice={
                                        product.attributes.total_amount_cents
                                    }
                                />
                            </Typography>
                        </Grid>
                        <Grid item style={{ margin: `auto`, marginBottom: 0 }}>
                            <DeleteCartItemButton itemId={product.id} />
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </ListItem>
    ) : (
        <Skeleton animation="pulse" height={200} variant="rect" />
    );
};
