import * as React from "react";
import {
    Avatar,
    IconButton,
    ListItem,
    ListItemText,
    Menu,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "../../context/authContext";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { Link } from "gatsby";
import { useUser } from "../users/hooks";

const useStyles = makeStyles((theme) => ({
    accountAvatar: {
        //backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
}));

export const UserAccountNav = () => {
    const { isLoading, data: user } = useUser();
    const classes = useStyles();
    const { logout } = useAuth();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton
                aria-controls="account-menu"
                aria-haspopup="true"
                aria-label="menu"
                onClick={handleClick}
            >
                <Avatar className={classes.accountAvatar}>
                    <PersonOutlineOutlinedIcon />
                </Avatar>
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                keepMounted
                onClose={handleClose}
                open={Boolean(anchorEl)}
            >
                <ListItem
                    button
                    component={Link}
                    key="Dashboard"
                    onClick={handleClose}
                    to="/dashboard"
                >
                    <ListItemText primary="Dashboard" />
                </ListItem>
                <ListItem
                    button
                    component={Link}
                    key="Account"
                    onClick={handleClose}
                    to="/dashboard/account/"
                >
                    <ListItemText primary="My account" />
                </ListItem>
                <ListItem
                    button
                    component={Link}
                    disabled={isLoading}
                    divider
                    key="Public profile"
                    onClick={handleClose}
                    to={`/user/${user && user.data && user.data.id}/`}
                >
                    <ListItemText primary="Public profile" />
                </ListItem>
                <ListItem button key="Logout" onClick={logout}>
                    <ListItemText primary="Logout" />
                </ListItem>
            </Menu>
        </div>
    );
};
