import * as React from "react";
import { Link } from "gatsby";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
    Button,
    Drawer,
    Grid,
    List,
    ListItem,
    Paper,
    Typography,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useUIDispatch, useUIState } from "../../app/uiContext";
import { useCart } from "./hooks";
import { Order } from "./Order";
import { CloseCartButton } from "./CloseCartButton";
import { CartTotalPrice } from "./CartTotalPrice";

export const SlideOverCart = () => {
    const drawerWidth = 400;
    const desktopDrawerWidth = 400;
    const useStyles = makeStyles((theme) => ({
        drawer: {
            [theme.breakpoints.up(`md`)]: {
                width: desktopDrawerWidth,
                flexShrink: 0,
            },
        },
        drawerPaper: {
            border: `none`,
            overflowX: `hidden`,
            width: drawerWidth,
        },
    }));
    const { cartOpen } = useUIState();
    const { isLoading, data: cart } = useCart();
    const uiDispatch = useUIDispatch();
    const classes = useStyles();
    const theme = useTheme();

    const handleDrawerToggle = (event) => {
        if (
            event.type === `keydown` &&
            (event.key === `Tab` || event.key === `Shift`)
        ) {
            return;
        }
        uiDispatch({ type: `HIDE_CART` });
    };
    const getItems = (order) => {
        return cart.included.filter((item) =>
            order.relationships.line_items.data.some(
                (li) => li.id === item.id && !!item.attributes.sku_code
            )
        );
    };

    return (
        <Drawer
            ModalProps={{
                keepMounted: true /* Better open performance on mobile.*/,
            }}
            PaperProps={{ elevation: 16 }}
            anchor={theme.direction === `rtl` ? `left` : `right`}
            classes={{ paper: classes.drawerPaper }}
            onClose={handleDrawerToggle}
            open={cartOpen}
            variant="temporary"
        >
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <CloseCartButton className={`float-right`} />
                    <Typography
                        align="center"
                        className={`pt-2`}
                        noWrap={true}
                        variant="h5"
                    >
                        Shopping cart
                    </Typography>
                </Grid>
            </Grid>
            <List className={`flex flex-col`}>
                {!isLoading && cart ? (
                    cart.data.map((order) => (
                        <Order
                            key={order.id}
                            lineItems={getItems(order)}
                            order={order}
                        />
                    ))
                ) : (
                    <Skeleton animation="pulse" height={400} variant="rect" />
                )}
                <ListItem>
                    <Paper className={`p-4 w-full`}>
                        <Typography display="inline">Subtotal</Typography>
                        <CartTotalPrice cart={cart} />
                    </Paper>
                </ListItem>
            </List>
            <Link className={`m-4`} to="/checkout">
                <Button color="primary" fullWidth variant="contained">
                    Checkout
                </Button>
            </Link>
        </Drawer>
    );
};
