import * as React from "react";
import { SvgIcon } from "@material-ui/core";

const OfferedInTrade = (props) => {
    return (
        <SvgIcon {...props}>
            <path d="M2,17H14.34L12.1,19.24l2.83,2.83L22,15,14.93,7.93,12.1,10.76,14.34,13H2ZM5.54,2,7,3.41,5.83,4.54H22v2H5.83L7,7.66,5.54,9.07,2,5.54,5.54,2Z" />
        </SvgIcon>
    );
};

export default OfferedInTrade;
