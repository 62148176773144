import * as React from "react";
import {
    Button,
    IconButton,
    ListItem,
    ListItemIcon,
    ListItemText,
    Tooltip,
} from "@material-ui/core";
import { useUIDispatch } from "../../app/uiContext";

export const LoginButton = ({
    buttonText,
    disabled = false,
    icon,
    type = ``,
    redirect,
    tooltip,
    ...props
}) => {
    return (
        <Tooltip title={tooltip || ``}>
            <span>
                <LoginBtn
                    disabled={disabled}
                    icon={icon}
                    redirect={redirect}
                    text={buttonText}
                    type={type}
                    {...props}
                />
            </span>
        </Tooltip>
    );
};

const LoginBtn = React.forwardRef(
    ({ disabled, icon, redirect, text, type, ...props }, ref) => {
        const uiDispatch = useUIDispatch();
        const handleClick = (event) => {
            event.preventDefault();
            uiDispatch({ type: `SHOW_LOGIN`, payload: redirect });
        };
        switch (type) {
            case `iconButton`:
                return (
                    <IconButton
                        color={props.color}
                        disabled={disabled}
                        onClick={handleClick}
                        {...props}
                        ref={ref}
                    >
                        {icon}
                    </IconButton>
                );
            case `button`:
                return (
                    <Button
                        disabled={disabled}
                        onClick={handleClick}
                        {...props}
                    >
                        {text}
                    </Button>
                );
            case `listItem`:
                return (
                    <ListItem
                        button
                        disabled={disabled}
                        key="Login"
                        onClick={handleClick}
                        {...props}
                    >
                        <ListItemIcon>{icon}</ListItemIcon>
                        <ListItemText primary={text} />
                    </ListItem>
                );
            default:
                return (
                    <button
                        disabled={disabled}
                        onClick={handleClick}
                        {...props}
                    >
                        {text}
                    </button>
                );
        }
    }
);
