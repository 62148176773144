import * as React from "react";
import PropTypes from "prop-types";
import { Button, ButtonGroup, FormControl, FormLabel } from "@material-ui/core";

export const CardQuantitySelector = ({
    fullWidth = false,
    handleQuantityChange,
    label = `Quantity`,
    margin = `normal`,
    maxQuantity,
    quantity,
    ...props
}) => {
    const handleIncrement = () => {
        const newQuantity =
            maxQuantity && quantity >= maxQuantity ? maxQuantity : quantity + 1;
        handleQuantityChange(newQuantity);
    };
    const handleDecrement = () => {
        const newQuantity = quantity > 1 ? quantity - 1 : 1;
        handleQuantityChange(newQuantity);
    };
    return (
        <FormControl fullWidth={fullWidth} margin={margin} {...props}>
            {label && <FormLabel id="quantity">{label}</FormLabel>}
            <ButtonGroup
                aria-label="quantity selector"
                className="mt-2"
                id="quantity"
            >
                <Button aria-label="less" onClick={handleDecrement}>
                    -
                </Button>
                <Button
                    aria-label="quantity"
                    disabled
                    style={{ color: `unset` }}
                >
                    {quantity}
                </Button>
                <Button aria-label="more" onClick={handleIncrement}>
                    +
                </Button>
            </ButtonGroup>
        </FormControl>
    );
};

CardQuantitySelector.propTypes = {
    fullWidth: PropTypes.bool,
    handleQuantityChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    margin: PropTypes.oneOf([`normal`, `dense`, `none`]),
    maxQuantity: PropTypes.number,
    quantity: PropTypes.number,
};
