import { useUser } from "../users/hooks";
import { v1 as uuidv1 } from "uuid";
import { useDispatch } from "react-redux";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
    createPayPalGateway,
    getMerchant,
    updatePayPalGateway,
} from "./client";
import {
    useInventoryModels,
    useMerchants,
    usePaymentMethods,
    useShippingMethods,
} from "../../hooks/useCommerceLayer";
import { addMessage } from "../alerts/messagesSlice";

export const useMerchantOnboardingProgress = () => {
    const user = useUser();
    const userId = Number(user && user.data && user.data.data.id);
    const filters = { u: userId };
    const { isLoading: isLoadingMerchants, data: merchants } = useMerchants({
        filters,
        enabled: !!userId,
    });
    const addressCompleted = !!(
        merchants &&
        merchants.meta &&
        merchants.meta.filter_count > 0
    );
    const { isLoading: isLoadingPayment, data: paymentMethod } =
        usePaymentMethods({ filters, enabled: !!userId });
    const paymentCompleted = !!(
        paymentMethod &&
        paymentMethod.meta &&
        paymentMethod.meta.record_count > 0
    );
    const { isLoading: isLoadingShipping, data: shippingMethod } =
        useShippingMethods({ filters, enabled: !!userId });
    const shippingCompleted = !!(
        shippingMethod &&
        shippingMethod.meta &&
        shippingMethod.meta.record_count > 0
    );
    const { isLoading: isLoadingInventory, data: inventory } =
        useInventoryModels({ filters, enabled: !!userId });
    const stockCompleted = !!(
        inventory &&
        inventory.meta &&
        inventory.meta.filter_count > 0
    );
    const isLoading =
        isLoadingMerchants ||
        isLoadingPayment ||
        isLoadingShipping ||
        isLoadingInventory;
    const allCompleted =
        addressCompleted &&
        paymentCompleted &&
        shippingCompleted &&
        stockCompleted;

    return {
        isLoading,
        addressCompleted,
        paymentCompleted,
        shippingCompleted,
        stockCompleted,
        allCompleted,
    };
};

export const useMerchant = (merchantId) => {
    return useQuery([`merchants`, merchantId], () => getMerchant(merchantId)); //, {staleTime:60000});
};

export const useCreatePayPalGateway = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation(createPayPalGateway, {
        onError: (err) => {
            dispatch(
                addMessage({
                    id: uuidv1(),
                    text: `Failed creating PayPal Gateway: ${err}`,
                    priority: `low`,
                })
            );
        },
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries(`payment_gateways`).then();
        },
    });
};
export const useUpdatePayPalGateway = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation(updatePayPalGateway, {
        onError: (err) => {
            dispatch(
                addMessage({
                    id: uuidv1(),
                    text: `Failed updating PayPal Gateway: ${err}`,
                    priority: `low`,
                })
            );
        },
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries(`payment_gateways`).then();
        },
    });
};
