import * as React from "react";
import {
    Button,
    ListItem,
    ListItemIcon,
    ListItemText,
} from "@material-ui/core";
import { useUIDispatch } from "../../app/uiContext";

export const RegisterButton = ({
    buttonText,
    type = ``,
    redirect,
    ...props
}) => {
    const uiDispatch = useUIDispatch();
    const handleClick = (event) => {
        event.preventDefault();
        uiDispatch({ type: `SHOW_REGISTER`, payload: redirect });
    };

    return (
        <>
            {type === `button` ? (
                <Button onClick={handleClick} {...props}>
                    {buttonText}
                </Button>
            ) : type === `listItem` ? (
                <ListItem button key="Join" onClick={handleClick} {...props}>
                    <ListItemIcon>
                        <></>
                    </ListItemIcon>
                    <ListItemText primary={buttonText} />
                </ListItem>
            ) : (
                <button onClick={handleClick} {...props}>
                    {buttonText}
                </button>
            )}
        </>
    );
};
