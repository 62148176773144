import * as React from "react";
import { Button, Paper } from "@material-ui/core";

export const ErrorFallback = ({ error, resetErrorBoundary }) => {
    return (
        <Paper className="p-4">
            <div role="alert">
                <p className="mb-4">Something went wrong:</p>
                <p>{error.message}</p>
                <Button
                    className="mt-4"
                    onClick={resetErrorBoundary}
                    variant="outlined"
                >
                    Try again
                </Button>
            </div>
        </Paper>
    );
};
