/* Commerce Layer integration */
import { directusClient } from "../../hooks/useDirectus";
import PropTypes from "prop-types";

export const getMerchant = () => {
    return directusClient.get(`/custom/merchants/0`);
};

export const createMerchant = (data) => {
    return directusClient.post(`/custom/merchants`, data);
};

export const createPayPalGateway = (data) => {
    return directusClient.post(`/custom/paypal_gateways`, data);
};

export const updatePayPalGateway = ({ id, updates }) => {
    return directusClient.patch(`/custom/paypal_gateways/${id}`, updates);
};

export const createPaymentMethod = (data) => {
    return directusClient.post(`/custom/payment_methods`, data);
};

createMerchant.PropTypes = {
    address: PropTypes.object.isRequired,
};
