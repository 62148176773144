import * as React from "react";
import { Link } from "gatsby";
import { Badge, IconButton } from "@material-ui/core";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { useMessageCount } from "./hooks";

export const MessagesButton = () => {
    const { data } = useMessageCount();

    return (
        <IconButton
            aria-label="Messages"
            component={Link}
            to="/dashboard/messages/"
        >
            <Badge badgeContent={data && data.count} color="secondary">
                <EmailOutlinedIcon />
            </Badge>
        </IconButton>
    );
};
