import * as React from "react";
import PropTypes from "prop-types";
import { IconButton, Tooltip } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import { useUIDispatch } from "../../app/uiContext";

export const CloseCartButton = ({ tooltip = `Close cart`, ...props }) => {
    const color = `primary`;
    const uiDispatch = useUIDispatch();
    const handleCloseCart = async () => {
        uiDispatch({ type: `HIDE_CART` });
    };

    return (
        <>
            <Tooltip title={tooltip} {...props}>
                <span>
                    <IconButton
                        aria-label={tooltip}
                        color={color}
                        disabled={false}
                        onClick={handleCloseCart}
                    >
                        <CloseIcon />
                    </IconButton>
                </span>
            </Tooltip>
        </>
    );
};

CloseCartButton.propTypes = {
    tooltip: PropTypes.string,
};
