import * as React from "react";
import { Button, Dialog, DialogContent, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useUIDispatch, useUIState } from "../../app/uiContext";
import { currencyData } from "../../utils/currency-data";

export const CurrencySelector = () => {
    const { displayCurrency } = useUIState();
    const uiDispatch = useUIDispatch();
    const selectedCurrency = currencyData.find(
        (currency) => currency.AlphabeticCode === displayCurrency
    );
    const [open, setOpen] = React.useState(false);
    const handleCurrencyChange = (event, values) => {
        // It's not a proper Select element so we're not using event.target.value
        if (values !== null) {
            uiDispatch({
                type: `SET_DISPLAY_CURRENCY`,
                payload: values.AlphabeticCode,
            });
            setOpen(false);
        }
    };
    const handleOnClick = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Button
                color="secondary"
                onClick={handleOnClick}
                size="small"
                variant="contained"
            >
                {displayCurrency}
            </Button>
            <Dialog
                aria-labelledby="dialog-title"
                fullWidth
                maxWidth="xs"
                onClose={handleClose}
                open={open}
                scroll="body"
            >
                <DialogContent className="mb-4">
                    <Autocomplete
                        autoComplete
                        fullWidth
                        getOptionLabel={(option) =>
                            `${option.AlphabeticCode} ${option.Currency}`
                        }
                        id="currency-selector"
                        onChange={handleCurrencyChange}
                        options={currencyData}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={`Choose your currency`}
                                margin="dense"
                            />
                        )}
                        required={true}
                        value={selectedCurrency}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
};
